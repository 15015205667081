import React from 'react';
import './Navbar.css';
import { Link } from "react-router-dom";
// import { F } from "../../../media/delhi/DelhiImages";
// import  { DelhiImages } from '../media/delhi/DelhiImages';
import logoImg from "../media/logo.jpg";
// Adjust the path and filename



export const Navbar = () => {


  return (
    <nav>
      <div className="logo">
        <Link to="/">
        <img src={logoImg} alt="logo" style={{ height: '120px', width: '160px' }} /> 
        </Link>
      </div>
      <input type="checkbox" id="check" />
      <label for="check" className="checkbtn">
        <i className="fa fa-bars"></i>
      </label>

      <ul className="list">
        <li id="active"><Link to="/">Home</Link></li>
        <li><Link to="/book" id="d">Book</Link></li>
        <li id="drop">
        <Link to="/locations" id="d">Domestic</Link>
          <ul>
            <li><Link to="/locations/goa" id="d" className='subMenu'>Goa</Link></li>
            <li>
            <Link to="/locations/uttarakhand" id="d" className='subMenu'>Uttarakhand</Link>
            </li>
            <li>
            <Link to="/locations/kerala" id="d" className='subMenu'>Kerala</Link>
            </li>
            <li>
            <Link to="/locations/chennai" id="d" className='subMenu'>Chennai</Link>
            </li>
            <li>
            <Link to="/locations/delhi" id="d" className='subMenu'>Delhi</Link>
            </li>
            <li>
            <Link to="/locations/rajasthan" id="d" className='subMenu'>Rajasthan</Link>
            </li>
            <li>
            <Link to="/locations" id="d" className='subMenu'>View all..</Link>
            </li>
          </ul>
        </li>
        <li id="drop">
        <Link to="/locations" id="d">International</Link>
          <ul>
            <li><Link to="/locations/dubai" id="d" className='subMenu'>Dubai</Link></li>
            <li><Link to="/locations/europe" id="d" className='subMenu'>Europe</Link></li>
            <li><Link to="/locations/srilanka" id="d" className='subMenu'>SriLanka</Link></li>
            <li><Link to="/locations/thailand" id="d" className='subMenu'>Thailand</Link></li>
            <li><Link to="/locations/maldives" id="d" className='subMenu'>Maldives</Link></li>
            <li><Link to="/locations" id="d" className='subMenu'>View all..</Link></li>
          </ul>
        </li>
        <li><Link to="/locations" id="d">Locations</Link></li>
        <li><Link to="/contact" id="d">Contact</Link></li>
      </ul>
    </nav>
  )
}