import React from 'react';
import "./PackageSection.css";
import { Link } from "react-router-dom";
import { PackageCard } from "./PackageCard";
import himachal from "../media/himachalbg.jpg";
import dubai from "../media/dubai.jpg";
import europe from "../media/europebg.jpg";
import kerala from "../media/keralabg.jpg";
import maldives from "../media/himachalbg.jpg";
import rajasthan from "../media/rajasthan.jpg";
import thailand from "../media/thailandbg.jpg";
import goa from "../media/goa.jpg";

export const PackageSection = () => {
  const packageInfoRow1 = [
    {
      place: "Golden Triangle",
      info: "Experience the Golden Triangle in comfort and style! Our tour offers expertly guided explorations, luxury stays, and curated itineraries, bringing you the best of Delhi, Agra, and Jaipur. Join us on an unforgettable Journey!",
      img: himachal
    },
    {
      place: "Rajasthan",
      info: "Embark on a journey through Rajasthan, the 'Land of Kings', where royal splendor, rich traditions, and stunning scenery await. Explore majestic palaces, historic forts, and vibrant markets that showcase the depth of India’s cultural heritage.",
      img: goa,
    },
    {
      place: "Kerala",
      info: "Explore Kerala's enchanting beauty and rich heritage, a tropical paradise on India’s southwestern coast. Known as 'God’s Own Country', it features lush landscapes, serene backwaters, and vibrant traditions for an unforgettable experience.",
      img: kerala,
    },
    {
      place: "South India",
      info: "Explore Tamil Nadu’s remarkable temples, celebrated for their intricate designs and spiritual significance. Visit the vibrant Meenakshi Temple in Madurai and the UNESCO-listed Brihadeeswarar Temple in Thanjavur, showcasing Dravidian architectural heritage.",
      img: rajasthan,
    },
  ];
  const packageInfoRow2 = [
    {
      place: "Dubai",
      info: "Dubai, where luxury meets innovation and tradition, offers unforgettable experiences from iconic skyscrapers to golden deserts. With Indi Rovers, embark on a journey to explore Dubai’s vibrant culture, thrilling attractions, and stunning landscapes.",
      img: dubai,
    },
    {
      place: "Nepal",
      info: "Nepal, a land of breathtaking Himalayan peaks, rich culture, and spiritual heritage, invites you to explore its vibrant landscapes and timeless traditions. Indi Rovers offers unforgettable journeys through Nepal’s awe-inspiring natural beauty and culture.",
      img: europe,
    },
    {
      place: "Bhutan",
      info: "Bhutan, the mystical Land of the Thunder Dragon, enchants travelers with breathtaking mountains, timeless monasteries, and sustainable tourism, offering a serene journey where spiritual depth and genuine happiness are valued far above material wealth.",
      img: thailand,
    },
    {
      place: "Bali",
      info: "Explore the enchanting beauty of Bali, a tropical paradise known for its stunning landscapes, vibrant culture, and warm hospitality. With pristine beaches, lush rice terraces, ancient temples, and bustling markets, Bali promises an unforgettable experience for every traveler seeking adventure, relaxation, and cultural immersion.",
      img: maldives,
    },
  ];
  return (
    <>
      <div className="container3">
        <section className="packages" id="packages">
          <h1>Best Selling Destinations!</h1>
          <div className="outerbox">
            <h2>
              Domestic
              <hr />
            </h2>
            <div className="row1">
              {packageInfoRow1.map((element) => {
                return (
                  <PackageCard
                    placeName={element.place}
                    img={element.img}
                    info={element.info}
                    price={element.price}
                    location={element.place.replace(/\s+/g, '')}
                  />
                );
              })}
            </div>
            <h2>
              International
              <hr />
            </h2>
            <div className="row2">
              {packageInfoRow2.map((element) => {
                return (
                  <PackageCard
                  placeName={element.place}
                  img={element.img}
                    info={element.info}
                    price={element.price}
                    location={element.place.replace(/\s+/g, '')}
                  />
                );
              })}
            </div>
            <div className="morebtn">
              <Link to="/locations">
                <button className="more-btn">Explore all</button>
              </Link>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};
