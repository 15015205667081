import React from 'react';
import "./Home.css";
import { Link } from "react-router-dom";
import bgvideo from "../media/home.mp4";
import { Services } from "../components/Services";
import { BookSection } from "../components/BookSection";
import { PackageSection } from "../components/PackageSection";
import { ContInfo } from "../components/ContInfo";

export const Home = () => {
  return (
    <>
      <div className="container">
        <section className="home" id="home">
          {/* <div className="content">
            <h3>
              Begin your <span id="diff">A</span>dventures with us
            </h3>
            <p>Explore the world with us for the best travel experience</p>
            <Link className="btn" to="/locations">
              <button className="exp-btn">Explore</button>
            </Link>
          </div> */}

          <div className="video-container">
            <video src={bgvideo} id="video-slider" loop autoPlay muted></video>
          </div>

          <div className="info">
            <div className="mail">
              <i className="fas fa-envelope" id="ficons"></i>
              <p>
                <a href="mailto:indirovers@gmail.com">
                indirovers@gmail.com
                </a>
              </p>
            </div>
            <div className="socialm">
              <i className="fab fa-instagram" id="ficons"></i>
              <a
                href="https://www.instagram.com/indirovers/?hl=en"
                target="_blank"
                rel="noopener noreferrer"
                style={{ textDecoration: 'none', color: 'inherit' }}
              >
                indirovers
              </a>
              </div>
              <div className="socialm">
              <i className="fab fa-tripadvisor" id="ficons"></i>
              <a
                href="https://www.tripadvisor.in/Attraction_Review-g304551-d29009197-Reviews-Indi_Rovers-New_Delhi_National_Capital_Territory_of_Delhi.html"
                target="_blank"
                rel="noopener noreferrer"
                style={{ textDecoration: 'none', color: 'inherit' }}
              >
                TripAdvisor
              </a>
              </div>
            <div className="call">
              <i className="fas fa-phone-alt" id="ficons"></i>
              <p>
                <a href="tel:8010622241">+91 9560642655</a>
              </p>
            </div>
          </div>
        </section>
      </div>
      <BookSection />
      <hr id="line" />
      <PackageSection />
      <hr id="line" />
      <Services />
      <hr id="line" />
      <ContInfo />
      <hr id="line" />
    </>
  );
};
