export const RajasthanImages ={
    d1 : require('./port1.jpg').default,
    d2 : require('./port2.jpg').default,
    db1 : require('./fam1.jpg').default,
    db2 : require('./fam2.jpg').default,
    dc1 : require('./cult1.jpg').default,
    dc2 : require('./cult2.jpg').default,
    dh1 : require('./food1.jpg').default,
    dh2 : require('./food2.jpg').default,
    dp1 : require('./pack1.jpg').default,
    dp2 : require('./pack2.jpg').default,
    dp3 : require('./pack3.jpg').default,
    dp4 : require('./pack4.jpg').default,
    do1 : require('./only1.jpg').default,
    do2 : require('./only2.jpg').default,
}