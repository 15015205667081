import React from 'react';
import "./Services.css";
import { ServiceCard } from "./ServiceCard";

export const Services = () => {
  const serviceInfoRow1 = [
    {
      header: "Best Flights",
      info: "Our airline booking service ensures effortless travel with competitive fares, flexible options, and dedicated support for a seamless experience.",
      icon: "fas fa-plane-departure",
    },
    {
      header: "Best deals",
      info: "Discover the best deals on hotels, cabs, attractions, and tour guides, ensuring memorable experiences at unbeatable prices.",
      icon: "fas fa-rupee-sign",
    },
    {
      header: "Railway Bookings",
      info: "Our railway booking service provides convenient options, competitive fares, and seamless travel experiences for your journey across the country.",
      icon: "fas fa-subway",
    },
  ];
  const serviceInfoRow2 = [
    {
      header: "Food And Drinks",
      info: "Indulge in a culinary journey with our food and drinks service, offering diverse options that enhance your travel experience and satisfy every palate.",
      icon: "fas fa-utensils",
    },
    {
      header: "Hotels",
      info: "Experience comfort and luxury with our hotel booking service, featuring a curated selection of accommodations to suit every traveler’s needs and budget.",
      icon: "fas fa-hotel",
    },
    {
      header: "Safe Travels",
      info: "Your safety is our priority. We provide expert guidance and resources to ensure safe travels, giving you peace of mind on every journey.",
      icon: "fas fa-clinic-medical",
    },
  ];

  return (
    <>
      <div className="container4">
        <section className="service" id="services">
          <h1>Have a look at our services!</h1>
          <div className="row2">
            {serviceInfoRow1.map((element) => {
              return (
                <ServiceCard
                  header={element.header}
                  info={element.info}
                  icon={element.icon}
                />
              );
            })}
          </div>
          <div className="row1">
            {serviceInfoRow2.map((element) => {
              return (
                <ServiceCard
                  header={element.header}
                  info={element.info}
                  icon={element.icon}
                />
              );
            })}
          </div>
        </section>
      </div>
    </>
  );
};
